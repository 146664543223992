<template>
	<!--	<el-dialog :title="'合同详情'" :close-on-click-modal="false" :visible.sync="visible"-->
	<!--		width="1000px">-->
	<div class="mod-config">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-card style="margin-top:10px; height: 100%;">
				<div class="elCardTitle">基本信息</div>
				<el-row class="set_border1">
					<el-col :span="8" class="col-set_border">
						<el-form-item label="合同名称" prop="contractName" class="item">
							<span>{{ dataForm.contractName }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8" class="col-set_border1">
						<el-form-item label="合同编号" prop="contractNo" class="item">
							<span>{{ dataForm.contractNo }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8" class="col-set_border1">
						<el-form-item label="签订时间" prop="contractTime" class="item">
							<span>{{ dataForm.contractTime }}</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="set_border">
					<el-col :span="8" class="col-set_border">
						<el-form-item label="签订单位" prop="contractCompany" class="item">
							<span>{{ dataForm.contractCompany }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="合同金额" prop="contractAmount" class="item">
							<span>{{ dataForm.contractAmount }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="8" class="col-set_border1">
						<el-form-item label="合同时间" prop="contractTimeList" class="item">
							<span>{{ dataForm.beginTime }}至{{ dataForm.endTime }}</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="set_border">
					<el-col :span="24">
						<el-form-item label="扫描件" class="item">
							<div class="addFileBox">
								<div v-if="dataForm.contractPath">
									<!-- <span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
                                        {{dataForm.loadFileName}}
                                    </span> -->
									<el-button v-preventReClick type="success" size="small"
										@click="selectFile()">查看</el-button>
								</div>
								<div v-if="!dataForm.contractPath">
									<span>暂无合同扫描文件</span>
								</div>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="set_border">
					<el-col :span="24">
						<el-form-item label="合同内容" prop="contractContent" class="item">
							<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
								v-model="dataForm.contractContent" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row class="set_border">
					<el-col :span="24">
						<el-form-item label="备注" prop="contractContent" class="item">
							<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.remark"
								:disabled="true"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-card>
			<el-card style="margin-top: 10px; height: 100%;">
				<div class="elCardTitle">变更记录</div>
				<el-table class="dataListTable" :data="changedLogDataList" header-align="center"
					style="width: 100%; margin-top: 20px;" header-cell-class-name="stepPointTHeader">
					<el-table-column type="index" width="60" align="center">
					</el-table-column>
					<el-table-column prop="changeItem" label="变更项目" width="200" align="center">
					</el-table-column>
					<el-table-column prop="changeContent" label="变更内容" align="center">
					</el-table-column>
					<el-table-column prop="changeRsn" label="变更原因 " width="275" align="center">
					</el-table-column>
					<el-table-column prop="changeTime" label="变更时间 " width="100" align="center">
					</el-table-column>
					<el-table-column label="变更" align="center">
						<el-table-column prop="changeWorkB" label="设备" width="80" align="center">
						</el-table-column>
						<el-table-column prop="changeWorkA" label="人" width="80" align="center">
						</el-table-column>
						<el-table-column prop="changeWorkC" label="工作量 " width="80" align="center">
						</el-table-column>
					</el-table-column>
				</el-table>
			</el-card>
			<el-card style="margin-top: 10px; height: 100%;">
				<div class="elCardTitle">付款记录</div>
				<el-table class="dataListTable" :data="paymentLogDataList" header-align="center"
					style="width: 100%; margin-top: 20px;" header-cell-class-name="stepPointTHeader">
					<el-table-column type="index" width="60" align="center">
					</el-table-column>
					<el-table-column prop="collectionItem" label="付款项目" width="250" align="center">
					</el-table-column>
					<el-table-column prop="billDate" label="付款日期" width="150" align="center">
					</el-table-column>
					<el-table-column prop="billAmount" label="付款金额" width="110" align="center">
					</el-table-column>
					<el-table-column prop="billNo" label="发票号" width="150" align="center">
					</el-table-column>
					<el-table-column prop="remark" label="备注" align="center">
					</el-table-column>
					<!--					<el-table-column prop="billFlag" label="开票标识" align="center">-->
					<!--						<template slot-scope="scope">-->
					<!--							<span v-if="scope.row.billFlag == 1">已开票</span>-->
					<!--							<span v-if="scope.row.billFlag == 0">未开票</span>-->
					<!--						</template>-->
					<!--					</el-table-column>-->
					<!--					<el-table-column prop="accountAmount" label="到账金额" width="150" align="center">-->
					<!--					</el-table-column>-->
					<!--					<el-table-column prop="accountDate" label="到账时间" width="150" align="center">-->
					<!--					</el-table-column>-->
					<!--					<el-table-column prop="accountFlag" label="到账标识" width="150" align="center">-->
					<!--						<template slot-scope="scope">-->
					<!--							<span v-if="scope.row.billFlag == 1">已到账</span>-->
					<!--							<span v-if="scope.row.billFlag == 0">未到账</span>-->
					<!--						</template>-->
					<!--					</el-table-column>-->
				</el-table>
			</el-card>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="rebtn" size="small" @click="backPage()">返回</el-button>
		</el-row>
		<!--	</el-dialog>-->
	</div>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "ownerContract-info",
	data() {
		return {
			visible: false,
			inline: false,
			action: "",
			fileList: [],
			changedLogDataList: [],
			paymentLogDataList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				contractId: "",
				contractTimeList: [],
				beginTime: "",
				endTime: "",
				contractAmount: "",
				contractContent: "",
				contractCompany: "",
				contractTime: "",
				contractName: "",
				contractNo: "",
				contractPath: "",
				contractFileUri: '',
				remark: "",
			},
			dataRule: {
				contractName: [
					{ required: true, message: "合同名称不能为空", trigger: "blur" }
				],
				contractNo: [
					{ required: true, message: "合同编号不能为空", trigger: "blur" }
				],
				contractTime: [
					{ required: true, message: "签订时间不能为空", trigger: "blur" }
				],
				contractCompany: [
					{ required: true, message: "签订单位不能为空", trigger: "blur" }
				],
				contractAmount: [
					{ required: true, message: "合同金额不能为空", trigger: "blur" }
				],
				contractTimeList: [
					{ required: true, message: "合同时间不能为空", trigger: "blur" }
				]
			},
		}
	},
	mounted() {
		this.dataForm.contractId = this.$route.params.contractId;
		this.getDetail();
		this.getChangedLogList();
		this.getPaymentLogList();
	},
	methods: {
		backPage() {
			this.$router.go(-1);
		},
		getChangedLogList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/ownercontractchange/list",
				method: "get",
				params: {
					contractId: this.dataForm.contractId
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.changedLogDataList = data.body.records;
				}
			});
		},
		getPaymentLogList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/ownercontractcollection/list",
				method: "get",
				params: {
					contractId: this.dataForm.contractId
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.paymentLogDataList = data.body;
				}
			});
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/ownercontractinfo/info/" + this.dataForm.contractId,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body
					// this.dataForm.contractTimeList=[data.body.beginTime,data.body.endTime]
				}
			});
		},
		selectFile() {
			window.open(this.dataForm.contractFileUri);
		},
		delFile() {
			this.dataForm.contractPath = "";
			this.dataForm.contractFileUri = "";
		},
		uploadFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/97/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.dataForm.contractPath = res.body.fileSavePath;
					this.dataForm.contractFileUri = res.body.fileUri;
					// this.fileList = [{
					// 	fileName: res.body.fileName,
					// 	filePath: res.body.fileSavePath,
					// 	fileUri: res.body.fileUri
					// }];
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/ownercontractinfo/save";
					if (this.dataForm.contractId && this.dataForm.contractId != 0) {
						_url = "/business/ownercontractinfo/update";
					}
					this.dataForm.beginTime = this.dataForm.contractTimeList[0];
					this.dataForm.endTime = this.dataForm.contractTimeList[1];
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
}
</script>
<style lang="scss"  scoped>
.elCardTitle {
	font-size: 18px;
	font-weight: bold;
	color: #00428e;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #00428e;
}

.selItemInput.descAreaLg {
	width: 1150px !important;
}

.item::v-deep .el-form-item__label {
    color: #00428e !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: 1px solid #dadada;
}

.row-form-item {
    margin-top: 10px;
}

.row-form-item .el-form-item {
    margin-right: 0;
}

.border-form .el-row.set_border {
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
}

.border-form .el-row.set_border1 {
    border: 1px solid #dadada;
}

.border-form .el-row.set_border .col-set_border {
    border-right: 1px solid #dadada;
}

.border-form .el-row.set_border .col-set_border1 {
    border-left: 1px solid #dadada;
}
.border-form .el-row.set_border1 .col-set_border1 {
    border-left: 1px solid #dadada;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}
</style>
